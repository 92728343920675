<template>
  <div class="downLoadApp">
    <div class="headLogo">
      <div>
        <div class="headTips" id="tips">
          点击右上角
          <i class="iconfont">&#xe75a;</i> 选择在浏览器中打开
          <i class="iconfont icof Isize">&#xe75c;</i>
        </div>
      </div>
      <div>
        <img
          src="@/assets/img/pic_log_logo.png"
          width="126px"
          height="126px"
          class="imgStyle"
        />
        <span class="imgTitle">福满多</span>
      </div>
      <div class="text">
        四川百璞归真商贸有限公司，起源于1978年，经历了41年的风雨历程，目前成为一家集珠宝玉器设计，生产，销售于一体的综合性企业。旗下“福满多”品牌已经成为全国知名的珠宝玉器品牌。
      </div>
      <van-button type="primary" class="donwBtn" @click="is_ios_adr"
        >下载APP</van-button
      >
      <div>
        <img src="@/assets/img/pic_bg.png" alt class="bg" />
        <div class="bgcolor">
          <div class="bujuFlex">
            <img src="@/assets/img/pic_phone.png" alt class="phone" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Sticky, Button } from "vant";

export default {
  data() {
    return {};
  },
  components: {
    [Sticky.name]: Sticky,
    [Button.name]: Button,
  },
  methods: {
    // 判断qq或者微信或者其他
    is_weixn_qq() {
      var ua = navigator.userAgent.toLowerCase();

      if (ua.match(/MicroMessenger/i) === "micromessenger") {
        return "weixin";
      } else if (ua.match(/QQ/i) === "qq") {
        return "QQ";
      }
      return false;
    },
    is_ios_adr() {
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
      if (isiOS) {
        console.log("跳转111111ios");
        setTimeout(function() {
          alert("跳转ios下载地址");
          // window.location.href = process.env.VUE_APP_BASE_IOS
        }, 1000);
      } else if (isAndroid) {
        console.log("跳转安卓");
        setTimeout(function() {
          alert("跳转安卓下载地址");
          // window.location.href = process.env.VUE_APP_BASE_ANDROID
        }, 1000);
      }
    },
  },
  mounted() {
    const flag = this.is_weixn_qq();
    console.log(flag);
    if (flag === false) {
      // 非qq 微信 去掉顶部提示
      document.getElementById("tips").style.display = "none";

      this.is_ios_adr();
    } else {
      document.getElementsByClassName("imgStyle")[0].style.marginTop = "66px";
      // document.getElementById("tips").style.display = 'none'
      this.is_ios_adr();
    }
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "iconfont"; /* project id 1722747 */
  src: url("//at.alicdn.com/t/font_1722747_g36yfh4ub0f.eot");
  src: url("//at.alicdn.com/t/font_1722747_g36yfh4ub0f.eot?#iefix")
      format("embedded-opentype"),
    url("//at.alicdn.com/t/font_1722747_g36yfh4ub0f.woff2") format("woff2"),
    url("//at.alicdn.com/t/font_1722747_g36yfh4ub0f.woff") format("woff"),
    url("//at.alicdn.com/t/font_1722747_g36yfh4ub0f.ttf") format("truetype"),
    url("//at.alicdn.com/t/font_1722747_g36yfh4ub0f.svg#iconfont") format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.icof {
  font-size: 21px;
}
.downLoadApp {
  text-align: center;
  // margin: 0 auto;
}
.headTips {
  width: 100%;
  height: 50px;
  background: #21a905;
  margin: 0 auto;
  font-size: 16px;
  line-height: 50px;
  color: #ffffff;
  position: fixed;
  top: 0px;
  z-index: 99;
}
.imgTitle {
  // text-align: center;
  display: block;
  font-size: 20px;
  color: #323332;
  font-family: PingFangSC;
  margin-bottom: 16px;
}
.text {
  width: 335px;
  height: 84px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: justify;
  color: #646664;
  line-height: 21px;
  margin: 0px auto;
}
.imgStyle {
  margin: 16px;
}
.bgcolor {
  width: 100%;
  height: 323px;
  background: #21a905;
  margin: 0 auto;
}
.bg {
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-top: 194px;
}
.phone {
  position: absolute;
  top: 414px;
  // bottom: -300px;
}
.bujuFlex {
  display: flex;
  justify-content: center;
}
.donwBtn {
  margin-top: 16px;
  width: 94%;
  border-radius: 24px;
  background: #21a905;
  font-family: PingFangSC, PingFangSC-Regular;
  color: #ffffff;
  font-size: 16px;
}
.Isize {
  font-size: 22px;
  font-stretch: expanded;
}
</style>
